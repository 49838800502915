import {
  Badge as MuiBadge,
  type BadgeProps as MuiBadgeProps,
  type SxProps,
  type Theme,
} from "@mui/material";

import type { BadgeSize } from "../theming/overrides/badge";

interface BadgeProps {
  count: number;
  children?: React.ReactNode;
  color?: MuiBadgeProps["color"];
  invisible?: boolean;
  max?: number;
  showZero?: boolean;
  size?: BadgeSize;
  variant?: MuiBadgeProps["variant"];
  sx?: SxProps<Theme>;
}

// TODO: Add Badge from MUI to eslint ignore pattern after we roll out the redesign
export function Badge(props: BadgeProps) {
  const { color = "primary", count, ...rest } = props;

  return <MuiBadge {...rest} color={color} badgeContent={count} />;
}
