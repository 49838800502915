import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { type Theme } from "@mui/material";
import { type ComponentProps } from "react";

import { formatDayOfMonthWithYear } from "../utils/formatDayOfMonthWithYear";

// TODO: Export TextProps from @clipboard-health/ui-react
interface AsOfDateTextProps extends ComponentProps<typeof Text> {
  date: Date;
  color?: (theme: Theme) => string | undefined;
}

/**
 * This component is used to confirm that whatever content it is paired with is up to date as of provided date.
 */
export function AsOfDateText(props: AsOfDateTextProps) {
  const { color: propsColor, variant = "caption", date, ...textProps } = props;

  return (
    <Text
      variant={variant}
      color={(theme) => {
        if (isDefined(propsColor)) {
          return propsColor(theme);
        }

        return theme.palette.text.secondary;
      }}
      {...textProps}
    >
      As of {formatDayOfMonthWithYear(date)}
    </Text>
  );
}
