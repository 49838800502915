import { Skeleton } from "@mui/material";

import {
  AVATAR_SIZE_LARGE,
  AVATAR_SIZE_MEDIUM,
  AVATAR_SIZE_SMALL,
  type AvatarSize,
} from "../theming/overrides/avatar";

interface AvatarSkeletonProps {
  size?: AvatarSize;
}

const skeletonSizeByAvatarSize = {
  small: AVATAR_SIZE_SMALL,
  medium: AVATAR_SIZE_MEDIUM,
  large: AVATAR_SIZE_LARGE,
};

export function AvatarSkeleton(props: AvatarSkeletonProps) {
  const { size: avatarSize = "medium" } = props;
  const skeletonSize = skeletonSizeByAvatarSize[avatarSize];

  return <Skeleton variant="circular" width={skeletonSize} height={skeletonSize} />;
}
