import { Clipboard } from "@capacitor/clipboard";
import constate from "constate";
import { useEffect, useState } from "react";

/**
 * Simple context that allows to copy text to clipboard and have a value indication if it was copied
 * Use it together with 'CopyToClipboardIcon' component
 */
export function useCopyToClipboard() {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
  }, [isCopied]);

  const copyToClipboard = async (text: string) => {
    await Clipboard.write({
      string: text,
    });

    setIsCopied(true);
  };

  return { isCopied, copyToClipboard };
}

export const [CopyToClipboardProvider, useCopyToClipboardContext] = constate(useCopyToClipboard);
