import { mergeSxProps, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, ButtonBase, Stack, type SxProps, type Theme } from "@mui/material";

import { CbhIcon, type IconType } from "./CbhIcon";

export interface CalloutProps {
  title: string;
  iconType?: IconType;
  text?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps<Theme>;
}

export function Callout(props: CalloutProps) {
  const { title, iconType, text, onClick, sx } = props;

  return (
    <ButtonBase
      sx={mergeSxProps(
        (theme) => ({
          backgroundColor: theme.palette.callout?.neutral.labelDark,
          color: theme.palette.callout?.neutral.text,
          borderRadius: theme.borderRadius?.xLarge,
          padding: 2,
          paddingLeft: 4,
          gap: 3,
          userSelect: "none",
          width: "100%",
          alignItems: "center",

          "& svg": {
            flexShrink: 0,
          },

          "& .MuiTypography-root": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
        }),
        sx
      )}
      onClick={onClick}
    >
      {iconType && <CbhIcon type={iconType} size="medium" />}

      <Stack
        direction="row"
        spacing={3}
        alignItems="baseline"
        sx={{ overflow: "hidden", marginRight: "auto" }}
      >
        <Text semibold variant="body1" sx={{ flexShrink: 0 }}>
          {title}
        </Text>

        {isDefined(text) && <Text variant="body2">{text}</Text>}
      </Stack>

      <Box
        // This is styled as icon button in secondary variant, but we obviously don't want to nest buttons,
        // so we're just using the Box here.
        sx={(theme) => ({
          display: "flex",
          height: "1.25rem",
          width: "1.25rem",
          boxSizing: "border-box",
          alignItems: "center",
          justifyContent: "center",
          flexShrink: 0,
          backgroundColor: theme.palette.background?.tertiary,
          boxShadow: theme.shadows[2],
          border: `1px solid ${String(theme.palette.border?.primary)}`,
          borderRadius: "50%",
          color: theme.palette.primary.main,
        })}
      >
        <CbhIcon type="chevron-right" size="xSmall" />
      </Box>
    </ButtonBase>
  );
}
