import { mergeSxProps } from "@clipboard-health/ui-react";
import { Box, type SxProps, type Theme } from "@mui/material";

import { CbhIcon } from "./CbhIcon";

interface CallToActionIconProps {
  sx?: SxProps<Theme>;
}

export function CallToActionIcon(props: CallToActionIconProps) {
  const { sx } = props;

  return (
    <Box
      className="call-to-action-icon"
      sx={mergeSxProps(
        (theme) => ({
          display: "flex",
          backgroundColor: theme.palette.background.tertiary,
          border: `1px solid ${String(theme.palette.border?.primary)}`,
          color: theme.palette.primary.main,
          boxShadow: theme.shadows[3],
          borderRadius: "50%",
          padding: "0.125rem",
          flexShrink: 0,

          // there is a small animation when clicking ListItemButton that scales the icon
          transformOrigin: "right",
          transition: theme.transitions.create(["transform"], {
            duration: theme.transitions.duration.standard,
          }),
        }),
        sx
      )}
    >
      <CbhIcon type="chevron-right" size="xSmall" />
    </Box>
  );
}
