import { CbhIcon, type IconSize } from "../CbhIcon";
import { SlideElementsTransition } from "../SlideElementsTransition";
import { useCopyToClipboardContext } from "./useCopyToClipboard";

interface CopyToClipboardIconProps {
  iconSize?: IconSize;
}

/**
 * Component that shows an indication if the text was copied
 * Use it together with 'CopyToClipboardProvider' context
 */
export function CopyToClipboardIcon(props: CopyToClipboardIconProps) {
  const { iconSize = "medium" } = props;

  const { isCopied } = useCopyToClipboardContext();

  return (
    <SlideElementsTransition
      direction="vertical"
      isSwitchedOn={isCopied}
      offElement={<CbhIcon type="copy" size={iconSize} />}
      onElement={
        <CbhIcon
          type="checkmark"
          size={iconSize}
          sx={(theme) => ({ color: theme.palette.success.main })}
        />
      }
    />
  );
}
