import { mergeSxProps, type UseModalState } from "@clipboard-health/ui-react";
import { Drawer, type SxProps, type Theme } from "@mui/material";
import { type ReactNode } from "react";

export interface BottomSheetProps {
  modalState: UseModalState;
  children: ReactNode;
  footer?: ReactNode;
  paperSx?: SxProps<Theme>;
}

export function BottomSheet(props: BottomSheetProps) {
  const { modalState, children, footer, paperSx } = props;
  const { modalIsOpen, closeModal } = modalState;

  return (
    <Drawer
      anchor="bottom"
      SlideProps={{
        // to make sure that if drawer is open while mounted, it will still animate
        appear: true,
      }}
      // render bottom sheets at the same index as modals
      sx={(theme) => ({ zIndex: theme.zIndex.modal })}
      open={modalIsOpen}
      PaperProps={{
        sx: mergeSxProps(
          (theme) => ({
            backgroundColor: theme.palette.background.tertiary,
            borderRadius: 8,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }),
          paperSx
        ),
      }}
      onClose={() => {
        closeModal();
      }}
    >
      {children}

      {footer}
    </Drawer>
  );
}
